define("discourse/plugins/stemaway-ui-addons/discourse/controllers/analytics", ["exports", "@ember/controller", "discourse/lib/ajax", "@ember/runloop", "@ember/service"], function (_exports, _controller, _ajax, _runloop, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _controller.default.extend({
    logs: null,
    pollTimer: null,
    router: (0, _service.inject)(),
    topicScores: null,
    points: 0,
    currentUser: (0, _service.inject)(),
    init() {
      this._super(...arguments);
      this.router.addObserver('currentRouteName', this, 'routeChanged');
      this.set('activeTab', 'activity');
      // this.loadUserPoints();
      // this.fetchAllTopicScores();
      // this.loadLogs();
    },
    routeChanged() {
      if (this.router.currentRouteName === 'analytics') {
        this.set('activeTab', 'activity');
        if (this.currentUser.username === this.model.username || this.currentUser.admin) {
          this.loadUserPoints();
          this.fetchUserAttempts();
          this.fetchAllTopicScores();
          this.loadLogs();
        }
      }
    },
    willDestroy() {
      this._super(...arguments);
      this.router.removeObserver('currentRouteName', this, 'routeChanged');
    },
    loadUserPoints() {
      if (!this.model?.username) {
        return;
      }
      (0, _ajax.ajax)(`/user-points/${this.model.username}`).then(data => {
        this.set('points', data.points);
      }).catch(() => {
        this.set('points', 0);
        console.error('Error fetching user points');
      });
    },
    loadLogs() {
      if (!this.model?.username) {
        return;
      }
      (0, _ajax.ajax)(`/custom_search/logs/${this.model.username}`).then(data => {
        this.set('logs', data.logs);
      }).catch(error => {
        console.error('Error fetching user logs:', error);
      });
    },
    fetchUserAttempts() {
      return (0, _ajax.ajax)(`/custom_search/fetch-all-user-attempts/${this.model.username}`).then(response => {
        if (response.userAttempts) {
          this.set('userAttempts', response.userAttempts);
        } else {
          this.set('userAttempts', {});
        }
      }).catch(() => {
        this.set('userAttempts', {});
      });
    },
    fetchAllTopicScores() {
      if (!this.model?.username) {
        return;
      }
      (0, _ajax.ajax)(`/custom_search/fetch-all-topic-scores/${this.model.username}`).then(response => {
        if ($.isEmptyObject(response.scores)) {
          this.set('topicScores', null);
        } else {
          this.set('topicScores', response.scores);
        }
      }).catch(error => {
        console.error('Error fetching topic scores:', error);
      });
    },
    actions: {
      toggleTab(tabName) {
        this.set('activeTab', tabName);
        if (tabName === 'activity') {
          this.loadLogs();
          this.loadUserPoints();
        } else if (tabName === 'scores') {
          this.fetchUserAttempts();
          this.fetchAllTopicScores();
        }
      }
    }
  });
});